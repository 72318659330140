@import url("https://fonts.googleapis.com/css?family=Inter");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.company_details_textarea {
  min-width: 90%;
  max-width: 90%;
  max-height: 20vh;
  min-height: 12vh;
  padding: 1.12rem 1.15rem;
  border-radius: 0;
  font-family: "Inter";
  border-color: #e0e0e0 !important;
  outline: none;
}
.company_details_textarea1 {
  min-width: 94%;
  max-width: 94%;
  max-height: 46px;
  min-height: 46px;
  padding: 1.12rem 1.15rem;
  border-radius: 0;
  font-family: "Inter";
  border-color: #e0e0e0 !important;
  outline: none;
}

.company_details_textarea:focus {
  border-radius: 0;
  border-color: #e0e0e0 !important;
  font-family: "Inter", sans-serif;
  outline: "none";
}

.textEditor_company_details {
  background: #fff;
  height: 100%;
}

.MuiPickersCalendarHeader-dayLabel {
  color: #0da4ce !important;
}

.MuiPickersDay-daySelected {
  background-color: #097395 !important;
  color: "#fff";
}

.rfqRequestType {
  text-overflow: ellipsis;
  max-width: 162px;
  overflow: hidden;
  white-space: nowrap;
}

.rfqRequestTypemobile {
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
}

.formikTextArea:focus {
  border-color: #e0e0e0 !important;
  outline: none;
}

.toolTipClass {
  max-width: 200px;
  overflow-wrap: break-word;
  display: block;

  @media (min-width: 600px) {
    max-width: 300px;
  }
}

.hideScrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hideScrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent;
}

.viewDialogLabel::-webkit-scrollbar {
  width: 4px;
  height: none;
  background-color: #097390;
}

.read-or-hide {
  color: #097390;
  cursor: pointer;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.pac-logo:after {
  display: none;
}

li ul {
  padding-left: 0;
}

li ul li {
  padding-left: 20px;
  position: relative;
  list-style-type: none;
}
li ul li:before {
  position: absolute;
  content: "-";
  left: 0;
  top: 0px;
}

.overlay-text {
  position: absolute;
  top: 20px; /* Adjust the top position as needed */
  left: 20px; /* Adjust the left position as needed */
  font-size: 24px; /* Adjust the font size as needed */
  color: white; /* Text color */
  /*background-color: rgba(0, 0, 0, 0.7); /* Background color with transparency */
  padding: 10px; /* Padding around the text */
  border-radius: 5px; /* Rounded corners */
}
